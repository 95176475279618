module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"develop":{"hardCacheMediaFiles":true,"nodeUpdateInterval":5000,"hardCacheData":false},"production":{"allow404Images":true,"hardCacheMediaFiles":false,"allow401Images":false},"schema":{"timeout":63000,"perPage":10,"requestConcurrency":1,"previewRequestConcurrency":1,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"type":{"MediaItem":{"localFile":{"excludeByMimeTypes":["audio/wav","application/zip"],"maxFileSizeBytes":15728640,"requestConcurrency":100},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"url":"https://admin.toolroomacademy.com/graphql","verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Toolroom Academy Website","short_name":"Toolroom Academy","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"standalone","icon":"src/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9806d491908edb4b58022068d4544cf1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-snipcart-advanced/gatsby-browser.js'),
      options: {"plugins":[],"version":"3.0.29","publicApiKey":"MTQ5MTk5ZGItYTM1ZS00NGMwLThmNDktMGRjNDg5MDcwMjU1NjM3NTMzMDI3NjE1MzExODUx","defaultLang":"en","currency":"gbp","openCartOnAdd":false,"useSideCart":false,"innerHTML":"\n        <card-product>\n            <div class=\"snipcart-card-product\">\n            <img\n                :src=\"image\"\n                :alt=\"name\"\n                class=\"snipcart-card-product__image\"\n            />\n            <div class=\"snipcart-card-product__infos\">\n                <div class=\"snipcart-card-product__name\">{{ name }}</div>\n                <div class=\"snipcart-card-product__price-ctn\">\n                    <div class=\"snipcart-card-product__quantity\">{{ quantity }}</div>\n                    <div class=\"snipcart-card-product__by\">×</div>\n                    <div class=\"snipcart-card-product__price\">{{ price | money(currency) }}</div>\n                </div>\n            </div>\n          </div>\n        </card-product>\n        \n       \n        "},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
